import React from "react";
import { Button, Modal } from "react-bootstrap";

import { ReactComponent as CloseIcon } from "../assets/images/close-icon.svg";

const ViewVisitingCardModal = ({ openModalData, setModalData }) => {
  const handleCloseModalImage = () => {
    setModalData({
      data: null,
      modal: false,
    });
  };
  return (
    <Modal
      size="md"
      show={openModalData?.modal}
      onHide={handleCloseModalImage}
      style={{
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        overflow: "hidden",
        height: "100vh",
      }}
      centered
    >
      <Modal.Body style={{ "text-align": "end" }}>
        <Button className="modal-close mb-1" onClick={handleCloseModalImage}>
          <CloseIcon />
        </Button>

        <div>
          <img
            src={openModalData?.data}
            style={{ width: "500px", height: "500px", objectFit: "fill" }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewVisitingCardModal;
