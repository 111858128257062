import { useState, useEffect, useRef } from "react";
import { ReactComponent as Close2Icon } from "../assets/images/close2-icon.svg";
import { post } from "../libs/http-hydrate";
import Auth from "../libs/auth";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import { customStyles } from "../utils/react-select-input-style";

function RequireProductsCategories({ data }) {
  let darkMode = localStorage.getItem("dark");
  if (darkMode === "false") {
    darkMode = false;
  } else {
    darkMode = true;
  }

  const [categoryData, setCategoryData] = useState({
    items: [],
    has_more: false,
  });
  const [categoryPage, setCategoryPage] = useState(0);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const navigate = useNavigate();
  const user = Auth.getCurrentUser();
  const catRef = useRef(null);

  // Fetch categories
  const fetchCategories = async (page = 1) => {
    try {
      const response = await post("/general/category_listing", {
        type: "product",
        page,
      });
      setCategoryData((prev) => ({
        items:
          page === 1
            ? response.data.data
            : [...prev.items, ...response.data.data],
        has_more: response.data.has_more,
      }));
    } catch {
      // alert("Something went wrong while fetching categories");
      console.log("Something went wrong while fetching categories");
    }
  };

  // Sync categories with server
  const syncCategories = async () => {
    const categoryIds = selectedCategories.map(
      (category) => category.value || category.id
    );
    try {
      const response = await post(
        "/user/profile/sync_product_category",
        { category_ids: categoryIds },
        { headers: { Authorization: `Bearer ${user.token}` } }
      );
      if (response.status === 200) {
        console.log("Categories updated successfully");
        window.location.reload(); // Reload to reflect changes
      }
    } catch (error) {
      const status = error.response?.status;
      const message = error.response?.data?.message;
      if (status === 400) console.log(message);
      else if (status === 404) console.log("Route Not Found");
      else console.log("Internal Server Error");
    }
  };

  // Add selected categories
  const handleAddCategories = () => {
    const selectedOptions = catRef?.current?.getValue() || [];
    const newCategories = selectedOptions.filter(
      (option) =>
        !selectedCategories.some((category) => category.value === option.value)
    );
    setSelectedCategories((prev) => [...prev, ...newCategories]);
  };

  // Remove category by index
  const handleRemoveCategory = (index) => {
    setSelectedCategories((prev) =>
      prev.filter((_, categoryIndex) => categoryIndex !== index)
    );
  };

  // Effect: Redirect unauthenticated users
  useEffect(() => {
    if (!user) navigate("/");
  }, [user, navigate]);

  // Effect: Fetch categories when page changes
  useEffect(() => {
    if (categoryPage > 0) fetchCategories(categoryPage);
  }, [categoryPage]);

  // Initial data setup
  UseEffectOnce(() => {
    setSelectedCategories(data || []);
    fetchCategories();
  });

  return (
    <div
      className="modal fade certifications-modal add-product-category"
      id="ProductCategoryModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="ProductCategoryModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ width: "600px" }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Require Products Categories</h5>
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">
                <Close2Icon />
              </span>
            </button>
          </div>
          <div className="modal-body">
            {/* Selected Categories */}
            <div className="tags tags-regular">
              {selectedCategories.map((category, index) => (
                <span key={index} className="tag">
                  {category.label || category.name}
                  <Close2Icon onClick={() => handleRemoveCategory(index)} />
                </span>
              ))}
            </div>

            {/* Add Category */}
            <div>
              <label className="form-label">Add Category</label>
              <div className="row align-items-center">
                <div className="col-8">
                  <Select
                    ref={catRef}
                    className="w-100"
                    placeholder="Select Category"
                    options={categoryData.items.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    isMulti
                    onMenuScrollToBottom={() => {
                      if (categoryData.has_more)
                        setCategoryPage((prev) => prev + 1);
                    }}
                    styles={customStyles(darkMode, true)}
                    menuPlacement="auto"
                  />
                </div>
                <div className="col-4 text-end">
                  <button
                    className="button button-blue w-100"
                    onClick={handleAddCategories}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="button button-primary"
              onClick={syncCategories}
              disabled={!selectedCategories.length} // Disable if no categories are selected
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequireProductsCategories;
