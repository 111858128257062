import React, { useEffect, useState } from "react";
import { ReactComponent as MyActivityIcon } from "../../assets/images/my-activity-icon.svg";
import ActivityReactionGif from "../../assets/images/activity-reaction.gif";
import ActivityCommentGif from "../../assets/images/activity-comment.gif";
import { get, getAuthConfig } from "../../libs/http-hydrate";

const MyActivityCard = ({ id }) => {
  let darkMode = localStorage.getItem("dark");
  if (darkMode === "false") {
    darkMode = false;
  } else {
    darkMode = true;
  }

  const [activityData, setActivityData] = useState({});
  const [isShowAllActivity, setIsShowAllActivity] = useState(false);

  useEffect(() => {
    const getActivities = async () => {
      try {
        const response = await get(
          `/user/profile/user_activity/${id}`,
          getAuthConfig()
        );
        setActivityData(response?.data?.data);
      } catch (error) {
        console.log(error, "error-activity");
      }
    };

    getActivities();
  }, []);

  const checkActivityType = (type, name) => {
    let text;
    switch (type) {
      case "reaction":
        text = `Reacted on ${name}`;
        break;
      case "comment":
        text = `Commented on ${name}`;
        break;
      default:
        text = "";
        break;
    }
    return text;
  };

  const checkActivityIcon = (type) => {
    let icon;
    switch (type) {
      case "reaction":
        icon = ActivityReactionGif;

        break;
      case "comment":
        icon = ActivityCommentGif;

        break;

      default:
        break;
    }
    return icon;
  };

  return (
    <div className="card">
      <div
        style={{
          borderBottom: darkMode ? "1px solid #383a3d" : "1px solid #BDBDBD",
        }}
        className="card-header card-header--large"
      >
        <h6 style={{ fontSize: "16px", display: "flex", alignItems: "center" }}>
          <MyActivityIcon />
          My Activity
        </h6>
      </div>
      <div style={{ paddingTop: 15 }} className="card-body">
        <div
          className="card-lists"
          style={{
            height: !activityData?.items?.length > 0 ? "20px" : "230px",
            overflowY: "auto",
          }}
        >
          {activityData.items && activityData.items.length > 0 ? (
            <>
              {activityData?.items?.map((item) => (
                <>
                  <div className="card-lists-item user-profile">
                    <div
                      className="user-profile-image"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "fit-content",
                      }}
                    >
                      <img
                        style={{ height: 20, width: 20 }}
                        src={checkActivityIcon(item?.type)}
                        alt="Img"
                        className="profile-pic"
                      />
                    </div>
                    <div className="user-profile-content">
                      <div className="user-profile-name">
                        <h6 style={{ fontWeight: 400, fontSize: "14px" }}>
                          {checkActivityType(item?.type, item?.activity?.name)}
                        </h6>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </>
          ) : (
            <>
              <p style={{ fontSize: "14px" }}>No Activity Found....</p>
            </>
          )}
        </div>
        {/* {activityData.items && activityData.items.length > 0 && (
          <div
            className="show-less-or-all-content"
            onClick={() => setIsShowAllActivity(!isShowAllActivity)}
          >
            <span> {isShowAllActivity ? "Show less" : "Show all"}</span>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default MyActivityCard;
