import { useContext, useState } from "react";
import { ReactComponent as Close2Icon } from "../assets/images/close2-icon.svg";
import Auth from "../libs/auth";
import { post, get } from "../libs/http-hydrate";
function DeleteModal(props) {
  const user = Auth.getCurrentUser();

  const deleteData = props.data;

  const handleDeletePost = () => {
    get(`/user/profile/show_case/delete/${deleteData.id}`, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((response) => {
        if (response.status === 200) {
          window.location.reload();
        }
      })
      .catch((e) => {
        alert("Something went wrong...");
      });
  };

  const reloadPage = () => {
    window.location.reload();
  };
  return (
    <>
      <div
        className="modal modal-md fade certifications-modal"
        id="DeleteModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="DeleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header justify-center">
              <h6 className="mb-0">
                Are you sure you want to remove this certificate ?
              </h6>
            </div>
            <div className="modal-footer justify-center">
              <button
                style={{ marginRight: "3%" }}
                className="button button-primary"
                onClick={handleDeletePost}
              >
                Yes
              </button>
              <button
                className="button button-primary button-primary--bordered"
                data-dismiss="modal"
                aria-label="Close"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeleteModal;
