import React from "react";
import { useNavigate } from "react-router-dom";

const urlRegex = /(https?:\/\/[^\s]+)/g; // Regex for URLs
const mentionedNameRegex = /@([\w\s]+)/g; // Regex for mentions like "@Firstname Lastname"

function DescriptionWithLinks({ description, connectionsUsers }) {
  const navigate = useNavigate();

  const renderContent = () => {
    // Step 1: Split the description into lines to preserve line breaks
    const lines = description.split("\n");

    return lines.map((line, lineIndex) => {
      // Step 2: Split the line using the URL regex
      const segments = line.split(urlRegex);

      return (
        <div key={`line-${lineIndex}`} style={{ marginBottom: "0.5em" }}>
          {segments.map((segment, index) => {
            if (urlRegex.test(segment)) {
              // Case 1: Segment is a URL, render as a clickable link
              return (
                <a
                  key={`url-${lineIndex}-${index}`}
                  href={segment}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "blue", fontSize: "bold" }}
                >
                  {segment}
                </a>
              );
            } else {
              // Step 3: Further split non-URL segments to handle mentions
              const mentionParts = segment.split(mentionedNameRegex);

              return mentionParts.map((part, subIndex) => {
                if (subIndex % 2 === 1) {
                  // Mentions (odd indices in the split array)

                  const trimmedPart = part.trim();
                  const matchedUser = connectionsUsers?.find(
                    (user) => user.name === trimmedPart
                  );

                  return (
                    <span
                      key={`mention-${lineIndex}-${index}-${subIndex}`}
                      style={{
                        color: "#19acda",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        navigate(`/edit-profile-seller/${matchedUser?.slug}`)
                      }
                    >
                      {trimmedPart}
                    </span>
                  );
                } else {
                  // Plain text (even indices in the split array)
                  return (
                    <span
                      key={`text-${lineIndex}-${index}-${subIndex}`}
                      style={{ color: "black" }}
                    >
                      {part}
                    </span>
                  );
                }
              });
            }
          })}
        </div>
      );
    });
  };

  return <div>{renderContent()}</div>;
}

export default DescriptionWithLinks;
