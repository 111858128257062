import { useContext, useState, useEffect, useRef } from "react";
import { ReactComponent as Close2Icon } from "../assets/images/close2-icon.svg";
import CompanyLogo from "../assets/images/company-logo.png";
import { ReactComponent as EditIcon } from "../assets/images/edit-icon2.svg";
import { ReactComponent as DeleteIcon } from "../assets/images/delete-icon.svg";
import { ReactComponent as TickIcon } from "../assets/images/tick-icon.svg";
import { post, get, getAuthConfig } from "../libs/http-hydrate";
import { resolvePath, useNavigate } from "react-router-dom";
import Auth from "../libs/auth";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import FieldValidationError from "../components/error-messages/field-validation-error";
import profilePlaceholder from "../assets/images/profile-placeholder.svg";
import { customStyles } from "../utils/react-select-input-style";

function EditInfo(props) {
  let darkMode = localStorage.getItem("dark");
  if (darkMode === "false") {
    darkMode = false;
  } else {
    darkMode = true;
  }
  const [imageLoadError, setImageLoadError] = useState(false);

  let placeHolderImageSrc =
    "https://dev-busimeet.s3.ap-south-1.amazonaws.com/default/user.png";
  const inputRef = useRef(null);
  const data = props.data;
  const initialIndustryTransformed =
    data?.company_details?.industry &&
    data?.company_details?.industry?.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  const initialIndustryNumber =
    data &&
    data?.company_details?.industry &&
    data?.company_details.industry?.map((industry) => industry.id);
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState({});
  const [userName, setUserName] = useState("");
  const [designation, setDesignation] = useState("");
  const [logo, setLogo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [about, setAbout] = useState("");
  const [industry, setIndustry] = useState(Array);
  // const [availableIndustry, setAvailableIndustry] = useState([]);
  const [availableIndustry, setAvailableIndustry] = useState({
    items: [],
    has_more: false,
  });
  const [industryOption, setIndustryOption] = useState([]);
  const [validateAbout, setValidateAbout] = useState(false);
  const [validateName, setValidateName] = useState(false);
  const [submitBtn, setSubmitBtn] = useState(false);
  const [establishData, setEstablishDate] = useState();
  const [optionSelected, setOptionSelected] = useState(
    initialIndustryTransformed
  );
  const [logoImage, setLogoImage] = useState("");
  const [imgPreview, setImgPreview] = useState();
  const [isUpdated, setIsUpdated] = useState(false);
  const [validateImg, setValidateImg] = useState(false);
  const [isNewProfile, setIsNewProfile] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [IndPage, setIndPage] = useState(0);

  const isRunned = useRef(false);

  const [companyLogor, setCompanyLogo] = useState({
    logo: "",
  });

  const IndustryRef = useRef(null);

  useEffect(() => {
    if (IndPage === 0) {
    } else {
      Industry();
    }
  }, [IndPage]);

  function Industry() {
    post(
      "/general/category_listing",
      { type: "industry", page: IndPage + 1 }
      // { headers: { Authorization: `Bearer ${user.token}` } }
    ).then((response) => {
      if (IndPage === 0) {
        setAvailableIndustry((p) => ({
          ...p,
          items: response?.data?.data,
        }));
      } else {
        setAvailableIndustry((p) => ({
          ...p,
          items: availableIndustry.items.concat(response?.data?.data),
        }));
      }
      setAvailableIndustry((p) => ({
        ...p,
        has_more: response?.data?.has_more,
      }));
      // setAvailableIndustry(response.data.data);
    });
  }

  function searcIndus(newValue) {
    post(
      "/general/category_listing",
      { type: "industry", page: 1, search: newValue }
      // { headers: { Authorization: `Bearer ${user.token}` } }
    )
      .then((response) => {
        setAvailableIndustry((p) => ({
          ...p,
          items: response?.data?.data,
        }));
        setAvailableIndustry((p) => ({
          ...p,
          has_more: response?.data?.has_more,
        }));
      })
      .catch((e) => {});
  }

  const user = Auth.getCurrentUser();

  useEffect(() => {
    if (isRunned.current) return;
    isRunned.current = true;
    if (data?.profile_image?.startsWith("https://")) {
      setLogoImage(data.profile_image);
      setIsNewProfile(true);
    }
    setIndustryOption(initialIndustryNumber);
    setDesignation(data?.company_details?.details?.designation);
    setLogo(data?.company_details?.details?.logo);
    setCompanyName(data?.company_details?.details?.name);
    setGstNumber(data?.company_details?.details?.gst_number);
    setEstablishDate(data?.company_details?.details?.establish_date);
    setUserName(data?.name);
    setAbout(data?.about);

    // post(
    //   "/general/category_listing",
    //   { type: "industry" },
    //   { headers: { Authorization: `Bearer ${user.token}` } }
    // ).then((response) => {
    //   setAvailableIndustry(response.data.data);
    // });
  }, []);

  useEffect(() => {
    setImgPreview(data?.profile_image);
    setLogoImage(data?.profile_image);
    setIsDeleted(false);
  }, [props?.showEditModal]);

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const imageValidation = (file) => {
    if (!file) {
      setValidateImg(true);
      setSubmitBtn(true);
    } else {
      setValidateImg(false);
      setSubmitBtn(false);
    }
  };

  const handleImageChange = (e) => {
    let img = { data: e.target.files[0] };
    setIsUpdated(true);
    setLogoImage(img);
    setImgPreview(img.data);
  };

  const handleIndustryChange = (selected) => {
    setOptionSelected(selected);
    const newArray = [];
    selected.forEach((select) => {
      if (!newArray.includes(select.value)) {
        newArray.push(select.value);
      }
    });
    setIndustryOption(newArray);
  };

  const formData = new FormData();
  let profile_image;
  if (logoImage) {
    if (typeof logoImage === "string") {
      if (logoImage?.startsWith("https://")) {
        fetch(logoImage)
          .then((response) => response.blob())
          .then(
            (blob) =>
              new File(
                [blob],
                `${logoImage.substring(logoImage.lastIndexOf("/") + 1)}`,
                {
                  type: blob.type,
                }
              )
          )
          .then((file) => {
            formData.append("profile_image", file, file.name);
          })
          .catch((error) => {
            console.error(
              "There has been a problem with your fetch operation:",
              error
            );
            // Handle the error or set a fallback image here
          });
      }
    } else {
      formData.append("profile_image", logoImage.data, logoImage.data.name);
    }
  } else if (isDeleted) {
    fetch(placeHolderImageSrc)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new File(
            [blob],
            `${placeHolderImageSrc.substring(
              placeHolderImageSrc.lastIndexOf("/") + 1
            )}`,
            {
              type: blob.type,
            }
          )
      )
      .then((file) => {
        formData.append("profile_image", file, file.name);
      })
      .catch((error) => {
        console.error("Error fetching placeholder image:", error);
        // Handle the error, for example, set a fallback image or display an error message
        // Example of setting a fallback image:
        //formData.append("profile_image", new File([], "fallback.jpg"));
      });
  }

  if (companyLogor?.logo != "") {
    formData.append("company_logo", companyLogor?.logo);
  }

  formData.append("name", userName);
  formData.append("about", about);
  if (designation) {
    formData.append("designation", designation);
  }
  if (companyName) {
    formData.append("company_name", companyName);
  }
  if (industryOption.length > 0) {
    industryOption.forEach((industry) =>
      formData.append("industry[]", industry)
    );
  }
  if (establishData) {
    formData.append("company_establish_date", establishData);
  }

  const editData = {
    name: userName,
    designation: designation,
    about: about,
    industry: industryOption,
    company_establish_date: establishData,
    profile_image: profile_image,
  };

  const postEditProfile = async () => {
    // if(companyLogor?.logo != ""){
    //   PostEditCompanyDetails();
    // }

    if (isDeleted) {
      removeProfileImage();
    }

    await post("/user/profile/edit", formData, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((response) => {
        if (response.status === 200) {
          // Update localStorage if profile image was successfully updated
          if (response?.data?.data?.profile_image) {
            // Retrieve the existing user object from localStorage
            let storedUser = JSON.parse(localStorage.getItem("user"));
            // Update the profile_image field in the user object
            storedUser.profile_image = response?.data?.data?.profile_image;
            // Save the updated user object back to localStorage
            localStorage.setItem("user", JSON.stringify(storedUser));
          }
          setTimeout(() => {
            props.getDetails();
            CloseRef.current.click();
            //  document.location.reload(true);
          }, 1000);
        }
      })
      .catch((e) => {
        if (e.response.status === 400) {
          // alert(`${e.response.data.message}`)
        }
        if (e.response.status === 404) {
          // alert("Route Not Found")
        }
        if (e.response.status === 500) {
          // alert("Internal Server Error")
        }
      });
  };

  async function PostEditCompanyDetails() {
    const fmdata = new FormData();
    if (companyLogor?.logo != "") {
      fmdata.append("logo", companyLogor?.logo);
    }
    fmdata?.append("name", data?.company_details?.details?.name);
    fmdata?.append("gst_number", data?.company_details?.details?.gst_number);
    fmdata?.append("pan_number", data?.company_details?.details?.pan_number);
    fmdata?.append("address", data?.company_details?.details?.office_address);
    fmdata?.append(
      "mobile_number",
      data?.company_details?.details?.mobile_number
    );
    fmdata?.append("email", data?.company_details?.details?.email);
    fmdata?.append("region_id", data?.company_details?.details?.region_id);
    fmdata?.append(
      "shipping_address",
      data?.company_details?.details?.shipping_address
    );
    fmdata?.append(
      "billing_address",
      data?.company_details?.details?.billing_address
    );
    if (data?.company_details?.details?.establish_date != null) {
      fmdata?.append(
        "billing_address",
        data?.company_details?.details?.establish_date
      );
    }

    await post("/user/setting/company_details/edit", fmdata, {
      headers: { Authorization: `Bearer ${user.token}` },
    }).then((response) => {
      if (response.status === 200) {
      }
    });
  }

  const handleAbout = (value) => {
    if (value.length === 0) {
      setValidateAbout(true);
      setSubmitBtn(true);
    } else if (value.length > 255) {
      setValidateAbout(true);
      setSubmitBtn(true);
    } else {
      setValidateAbout(false);
      setSubmitBtn(false);
    }
  };
  const handleName = (value) => {
    if (value.length === 0) {
      setValidateName(true);
      setSubmitBtn(true);
    } else if (value.length > 255) {
      setValidateName(true);
      setSubmitBtn(true);
    } else {
      setValidateName(false);
      setSubmitBtn(false);
    }
  };
  const handleClick = () => {
    inputRef.current.click();
  };
  const handleDeleteProfileImage = () => {
    setLogoImage("");
    setImgPreview();
    setIsDeleted(true);
    // removeProfileImage();
  };
  const LogoRef = useRef(null);

  // Function to remove the profile image
  const removeProfileImage = async () => {
    try {
      const response = await get(
        "/user/profile/remove_profile_image",
        getAuthConfig()
      );
      if (response.status === 200) {
        // Handle successful response
        setLogoImage("");
        setImgPreview();
        setIsDeleted(true);
      }
    } catch (error) {
      console.error("Error removing profile image:", error);
      // Handle error response
    }
  };

  const CloseRef = useRef(null);

  return (
    <>
      <div
        className="modal fade edit-detail-modal"
        id="EditInfo"
        tabindex="-1"
        role="dialog"
        aria-labelledby="EditInfoLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div
              style={{
                borderBottom: darkMode
                  ? "1px solid #383a3d"
                  : "1px solid #BDBDBD",
              }}
              className="modal-header"
            >
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Profile
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref={CloseRef}
                onClick={() => {
                  props?.setShowEditModal(false);
                }}
              >
                <span aria-hidden="true">
                  <Close2Icon />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="form-field col-sm-6">
                  <label className="form-label">
                    Full Name <span className="mendatory">*</span>
                  </label>
                  <input
                    type="text"
                    name="fname"
                    id="fname"
                    className="form-input"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="Enter Full Name"
                    onBlur={(e) => handleName(e.target.value)}
                    required
                    style={{
                      border: darkMode
                        ? "1px solid #383a3d"
                        : "1px solid #BDBDBD",
                    }}
                  />
                  {validateName ? (
                    <FieldValidationError message="Name Is Required!" />
                  ) : null}
                </div>
                {data?.i_am === "employee" ? (
                  <>
                    <div className="form-field col-sm">
                      <label className="form-label">
                        Designation <span className="mendatory">*</span>
                      </label>
                      <input
                        type="text"
                        name="designation"
                        id="designation"
                        className="form-input"
                        value={designation}
                        onChange={(e) => setDesignation(e.target.value)}
                        placeholder="Enter Designation"
                        required
                        style={{
                          border: darkMode
                            ? "1px solid #383a3d"
                            : "1px solid #BDBDBD",
                        }}
                      />
                    </div>
                  </>
                ) : null}
              </div>
              <div className="form-field">
                <label className="form-label">
                  About <span className="mendatory">*</span>
                </label>
                <textarea
                  className="form-input"
                  placeholder="About"
                  value={about}
                  onChange={(e) => setAbout(e.target.value)}
                  onBlur={(e) => handleAbout(e.target.value)}
                  style={{
                    border: darkMode
                      ? "1px solid #383a3d"
                      : "1px solid #BDBDBD",
                  }}
                ></textarea>
                {validateAbout ? (
                  <FieldValidationError message="About Is Required!" />
                ) : null}
              </div>

              <div className="upload">
                <div
                  style={{
                    border: darkMode
                      ? "1px solid #383a3d"
                      : "1px solid #BDBDBD",
                  }}
                  className="upload-images"
                >
                  {
                    <div className="upload-item">
                      <label className="form-label">Company Logo</label>
                      <div className="upload-image image-lists">
                        <div className="img-block w-100 h-100">
                          <input
                            className="img-block"
                            ref={LogoRef}
                            type="file"
                            placeholder="Upload Newer Image"
                            onChange={(e) => {
                              e.preventDefault();
                              setCompanyLogo((p) => ({
                                ...p,
                                logo: e.target.files[0],
                              }));
                            }}
                            onBlur={(e) => {
                              e.preventDefault();
                            }}
                          />

                          <img
                            src={
                              companyLogor?.logo != ""
                                ? URL.createObjectURL(companyLogor?.logo)
                                : logo
                            }
                            alt="test"
                          />
                          <div className="actions">
                            <button
                              className="button-edit"
                              onClick={(e) => {
                                LogoRef.current.click();
                              }}
                            >
                              <EditIcon />
                            </button>
                            <button
                              className="button-delete"
                              onClick={handleDeleteProfileImage}
                            >
                              <DeleteIcon />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  <div className="upload-item">
                    <label className="form-label">Profile Picture</label>
                    <div className="upload-image image-lists">
                      {isUpdated ? (
                        <>
                          <div className="img-block">
                            <input
                              className="img-block"
                              ref={inputRef}
                              type="file"
                              placeholder="Upload Newer Image"
                              onChange={handleImageChange}
                              onBlur={(e) => imageValidation(e.target.files[0])}
                            />
                            <img
                              alt=""
                              src={
                                imgPreview
                                  ? URL.createObjectURL(imgPreview)
                                  : placeHolderImageSrc
                              }
                            />
                            <div className="actions">
                              <button
                                className="button-edit"
                                onClick={handleClick}
                              >
                                <EditIcon />
                              </button>
                              <button
                                className="button-delete"
                                onClick={handleDeleteProfileImage}
                              >
                                <DeleteIcon />
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="img-block">
                            <input
                              className="img-block"
                              ref={inputRef}
                              type="file"
                              placeholder="Upload New Image"
                              onChange={handleImageChange}
                              onBlur={(e) => imageValidation(e.target.files[0])}
                            />
                            {imageLoadError ? (
                              // Display a fallback image or placeholder
                              <img
                                src="path_to_fallback_image_or_placeholder"
                                alt="Profile Image"
                              />
                            ) : (
                              <img
                                alt=""
                                src={logoImage || placeHolderImageSrc}
                                onError={() => setImageLoadError(true)}
                              />
                            )}

                            <div className="actions">
                              <button
                                className="button-edit"
                                onClick={handleClick}
                              >
                                <EditIcon />
                              </button>
                              <button
                                className="button-delete"
                                onClick={handleDeleteProfileImage}
                              >
                                <DeleteIcon />
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="upload-fields">
                  <div className="row">
                    <div className="col-sm">
                      <h6>Company Details</h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-field col-sm">
                      <label className="form-label">
                        Company Name <span className="mendatory"></span>
                      </label>
                      <input
                        type="text"
                        name="cname"
                        id="cname"
                        className="form-input"
                        onChange={(e) => setCompanyName(e.target.value)}
                        value={companyName}
                        placeholder="Enter Company Name"
                        style={{
                          border: darkMode
                            ? "1px solid #383a3d"
                            : "1px solid #BDBDBD",
                        }}
                      />
                    </div>
                    <div className="form-field col-sm verified-icon">
                      <label className="form-label">
                        GST No <span className="mendatory"></span>
                      </label>
                      <input
                        type="text"
                        name="cname"
                        id="cname"
                        className="form-input"
                        onChange={(e) => setGstNumber(e.target.value)}
                        value={gstNumber}
                        placeholder="Enter GST No"
                        disabled
                        style={{
                          border: darkMode
                            ? "1px solid #383a3d"
                            : "1px solid #BDBDBD",
                        }}
                      />
                      {gstNumber && <TickIcon />}
                    </div>
                    <div className="form-field col-sm">
                      <label className="form-label">
                        ESTD<span className="mendatory"></span>
                      </label>
                      <input
                        type="date"
                        name="cname"
                        id="cname"
                        className="form-input"
                        value={establishData}
                        onChange={(e) => setEstablishDate(e.target.value)}
                        style={{
                          border: darkMode
                            ? "1px solid #383a3d"
                            : "1px solid #BDBDBD",
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-field col-sm">
                      <label className="form-label">
                        Industry <span className="mendatory"></span>
                      </label>
                      {/* <select  name='cname' id='cname' className='form-input' placeholder={handleIndustryData(industryOption)} value={handleIndustryData(industryOption)} onChange={(e)=> {handleChange(e.target.value)}}>
                  <option disabled selected value=''>Please Select The Industry</option>
                {availableIndustry.map((industry) => (
              <option value={industry.id}>{industry.name}</option>
              ))}
                </select> */}
                      <ReactSelect
                        options={
                          availableIndustry?.items &&
                          availableIndustry?.items.map(function (productArray) {
                            return {
                              value: productArray.id,
                              label: productArray.name,
                            };
                          })
                        }
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                        }}
                        onChange={(selected) => handleIndustryChange(selected)}
                        allowSelectAll={true}
                        value={optionSelected}
                        onFocus={(e) => {
                          e.preventDefault();
                          Industry();
                        }}
                        onKeyDown={(e) => {
                          searcIndus(e.target.value);
                        }}
                        onMenuScrollToBottom={(e) => {
                          if (availableIndustry?.has_more === true) {
                            let p = IndPage + 1;
                            setIndPage(p);
                          }
                        }}
                        styles={customStyles(darkMode, true)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                borderTop: darkMode ? "1px solid #383a3d" : "1px solid #BDBDBD",
              }}
              className="modal-footer"
            >
              <button
                type="button"
                disabled={submitBtn}
                className="button button-primary"
                style={{ borderRadius: "5px" }}
                onClick={(e) => {
                  postEditProfile(e);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditInfo;
